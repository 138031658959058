<template>
  <div class="warehouse-viewer" ref="warehouse_viewer">
    <div
      class="failed badge w-100"
      v-if="warehouse === null || warehouse === undefined"
    >
      <span class="bold-19">{{
        $t("productHistory.noWarehouseSelected2")
      }}</span>
    </div>
    <div v-show="warehouse > 0">
      <div class="d-flex">
        <button
          class="btn btn-no-r"
          :class="mode == 'stock' ? 'btn-primary' : 'btn-secondary'"
          v-on:click="mode = 'stock'"
        >
          Stock
        </button>
        <button
          class="btn btn-no-l btn-no-r"
          :class="mode == 'editor' ? 'btn-primary' : 'btn-secondary'"
          v-on:click="mode = 'editor'"
        >
          Editor
        </button>
        <button
          class="btn btn-no-l"
          :class="
            mode == 'unallocatedInventory' ? 'btn-primary' : 'btn-secondary'
          "
          v-on:click="loadUnallocatedInventory"
        >
          Unallocated stock
        </button>
      </div>
      <div id="ui" v-if="mode == 'unallocatedInventory'">
        <div class="row mb-3">
          <div class="col-6 bold-12">Product</div>
          <div class="col-3 bold-12">Allocated stock</div>
          <div class="col-3 bold-12">Warehouse stock</div>
        </div>
        <div class="row" v-for="item in unallocatedInventory" :key="item.id">
          <div class="col-6">
            <span class="bold-12">{{ item.serial }}</span>
            <span class="ms-1 regular-12">{{
              item.sku ?? item.ean ?? item.upc
            }}</span>
          </div>
          <div class="col-3">
            {{ item.slots_quantity }}
          </div>
          <div class="col-3">
            {{ item.product_quantity }}
          </div>
        </div>
      </div>
      <div id="ui" v-else-if="selectedRack || editorMode">
        <div class="row align-items-center">
          <div class="col">
            <h1 class="bold-24">Warehouse Editor</h1>
          </div>
          <div class="col-auto">
            <button
              v-if="selectedRack"
              class="btn btn-transparent"
              v-on:click="
                selectedRack = null;
                selectedRow = null;
              "
            >
              back
            </button>
          </div>
        </div>
        <div v-if="selectedRack">
          <div v-if="editorMode">
            <div class="text-start m-2 custom-input">
              <div class="row">
                <div class="col-8 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray"> Aisle </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectedRow.userData.aisle"
                    v-on:change="generateRackNumbers"
                    placeholder="123"
                  />
                </div>
                <div class="col-4 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray"> Rack </label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="selectedRack.userData.rack"
                    placeholder="01"
                  />
                </div>
                <div class="col-12 d-flex gap-2">
                  <button v-on:click="rotate()" class="btn btn-outline-primary">
                    Rotate
                  </button>
                  <button
                    v-on:click="removeRow(selectedRow)"
                    class="btn btn-outline-danger"
                  >
                    Remove
                  </button>
                  <button
                    v-on:click="print()"
                    class="btn btn-outline-primary"
                    v-if="selectedRow.userData.id > 0"
                  >
                    Print
                  </button>
                </div>
                <div class="col-12 row align-items-center">
                  <div class="col">
                    <h2 class="bold-19">Slots</h2>
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn btn-small btn-primary"
                      v-on:click="addSlot()"
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div
                  v-for="slot in selectedRack.userData.slots"
                  :key="slot"
                  class="col-12 row"
                >
                  <div class="col-4 whitebg-input">
                    <label class="form-label bold-12 text-gray">
                      Slot number
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="slot.number"
                      placeholder="1"
                    />
                  </div>
                  <div class="col-8 whitebg-input">
                    <label class="form-label bold-12 text-gray">
                      Slot size
                    </label>
                    <v-select
                      v-model="slot.size"
                      :options="sizes"
                      label="name"
                      :reduce="(a) => a.id"
                      append-to-body
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          <div class="m-2">
                            <small>
                              {{ $t("editOnClick.noResultsFoundFor") }}
                              <em>{{ search }}</em
                              >.
                            </small>
                          </div>
                          <div class="m-2">
                            <button
                              class="btn btn-small btn-primary"
                              v-on:click="sizesAddModal"
                            >
                              <small>Add new size</small>
                            </button>
                          </div>
                        </template>
                        <em v-else style="opacity: 0.5">{{
                          $t("crmItemFinder.startTypingToSearch")
                        }}</em>
                      </template>
                      <template v-slot:option="option">
                        <div class="small mt-1">{{ option.name }}</div>
                        <div>
                          {{ option.length }}x{{ option.with }}x{{
                            option.height
                          }}
                        </div>
                      </template>
                      <template v-slot:selected-option="option">
                        <div class="mt-3 fw-bold">{{ option.name }}</div>
                        <div class="ms-3 mt-3">
                          {{ option.length }}x{{ option.with }}x{{
                            option.height
                          }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <h2 class="mb-2">
              {{ selectedRow.userData?.aisle }}-{{
                selectedRack.userData?.rack
              }}
            </h2>
            <template
              v-for="slot in selectedCompartments?.slots"
              :key="slot.id"
            >
              <tepmlate v-if="slot?.itemSlots?.length > 0">
                <div class="row" v-for="item in slot?.itemSlots" :key="item.id">
                  <div class="col-2 fw-bold">/{{ slot.rack_number }}</div>
                  <div class="col-6">
                    {{ item.serial }}
                  </div>
                  <div class="col-3 text-right">
                    {{ item.quantity }}
                  </div>
                </div>
              </tepmlate>
              <div class="row" v-else>
                <div class="col-2 fw-bold">/{{ slot.rack_number }}</div>
                <div class="col-6">(Empty slot)</div>
              </div>
            </template>
          </div>
        </div>
        <div v-else-if="editorMode">
          <h4>Warehouse</h4>
          <div class="row">
            <div class="col-6 whitebg-input">
              <label class="form-label bold-12 text-gray"> Width </label>
              <input
                class="form-control"
                type="number"
                v-model="warehouseWidth"
              />
            </div>
            <div class="col-6 whitebg-input">
              <label class="form-label bold-12 text-gray"> Height </label>
              <input
                class="form-control"
                type="number"
                v-model="warehouseHeight"
              />
            </div>
          </div>
          <h4>Add Row</h4>
          <div class="row">
            <div class="col-6 whitebg-input">
              <label class="form-label bold-12 text-gray"> Racks number </label>
              <input
                class="form-control"
                type="number"
                v-model="numCompartments"
              />
            </div>
            <div class="col-6 whitebg-input">
              <label class="form-label bold-12 text-gray"> Rack length </label>
              <input
                class="form-control"
                type="number"
                v-model="newCompartmentLength"
              />
            </div>
            <div class="col-12 whitebg-input">
              <label class="form-label bold-12 text-gray">
                Shelfs number
              </label>
              <input
                class="form-control"
                type="number"
                v-model="newCompartmentShelfs"
              />
            </div>
            <template v-for="i in newCompartmentShelfs" :key="i">
              <div class="col-6 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ i }} shelf slots number
                </label>
                <input
                  class="form-control"
                  type="number"
                  v-model="newCompartmentSlots[i]"
                />
              </div>
              <div class="col-6 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ i }} shelf slot sizes
                </label>
                <v-select
                  v-model="newCompartmentSlotSizes[i]"
                  :options="sizes"
                  label="name"
                  :reduce="(a) => a.id"
                  append-to-body
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      <div class="m-2">
                        <small>
                          {{ $t("editOnClick.noResultsFoundFor") }}
                          <em>{{ search }}</em
                          >.
                        </small>
                      </div>
                      <div class="m-2">
                        <button
                          class="btn btn-small btn-primary"
                          v-on:click="sizesAddModal"
                        >
                          <small>Add new size</small>
                        </button>
                      </div>
                    </template>
                    <em v-else style="opacity: 0.5">{{
                      $t("crmItemFinder.startTypingToSearch")
                    }}</em>
                  </template>
                  <template v-slot:option="option">
                    <div class="small mt-1">{{ option.name }}</div>
                    <div>
                      {{ option.length }}x{{ option.with }}x{{ option.height }}
                    </div>
                  </template>
                  <template v-slot:selected-option="option">
                    <div class="mt-3 fw-bold">{{ option.name }}</div>
                    <div class="ms-3 mt-3">
                      {{ option.length }}x{{ option.with }}x{{ option.height }}
                    </div>
                  </template>
                </v-select>
              </div>
            </template>
          </div>
          <button v-on:click="addRow()" class="btn btn-primary">Add Row</button>
          <div class="mt-5 d-flex gap-3">
            <button v-on:click="saveWarehouse()" class="btn btn-primary">
              Save
            </button>
            <!--<button
              v-on:click="refreshFullness()"
              class="btn btn-outline-secondary"
            >
              Refresh stock
            </button>
            <button v-on:click="setMovement()" class="btn btn-outline-secondary">
              set Movement
            </button>-->
          </div>
        </div>
      </div>
      <SizeEditor ref="addSizeModal" v-on:save="createNewSize" />
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { MeshLine, MeshLineMaterial } from "three.meshline";
import http from "../../../modules/http";
import { useStore } from "vuex";
import SizeEditor from "../../../components/modals/SizeEditor.vue";
import printJS from "print-js";
import tenant from "../../../modules/tenant";

let scene = null;
let camera = null;
let renderer = null;

let zoomDistance = 10;
let wall1,
  wall2,
  wall3,
  wall4,
  floor,
  gridHelper = null;

export default {
  components: { SizeEditor },
  data() {
    return {
      store: useStore(),
      warehouseWidth: 10,
      warehouseHeight: 10,
      rows: [],
      selectedRow: null,
      selectedRack: null,
      mode: "stock",
      numCompartments: 5,
      newCompartmentLength: 0.5,
      newCompartmentShelfs: 1,
      newCompartmentSlots: {},
      newCompartmentSlotSizes: {},
      sizes: [],
      topTexture: null,
      sideTextureFront: null,
      sideTextureBack: null,
      sideTextureRight: null,
      sideTextureLeft: null,
      unallocatedInventory: [],
      animationFrameId: null,
      onWindowResize: null,
    };
  },
  beforeUnmount() {
    // Clean up animation frame
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    // Dispose of renderer and remove DOM element
    renderer.dispose();
    if (renderer.domElement.parentNode) {
      renderer.domElement.parentNode.removeChild(renderer.domElement);
    }

    // Remove window event listener
    window.removeEventListener("resize", this.onWindowResize);
  },
  mounted() {
    // Set up scene, camera, and renderer
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(
      75,
      (this.$refs.warehouse_viewer.offsetWidth - 40) /
        (window.innerHeight - 180),
      0.1,
      1000
    );
    renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(
      this.$refs.warehouse_viewer.offsetWidth - 40,
      window.innerHeight - 180
    );
    document.body.appendChild(renderer.domElement);

    this.setWarehouseSize();

    // Mouse controls
    let isRightMouseDown = false;
    let isLeftMouseDown = false;
    let prevMousePosition = { x: 0, y: 0 };

    // Camera settings
    camera.position.set(0, 0, 10);
    camera.lookAt(0, 0, 0);
    let zoomSpeed = 1;
    let cameraTarget = new THREE.Vector3(0, 0, 0);

    // Light settings
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0, 5, 20);
    directionalLight.target.position.set(0, 0, 0);
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    const ambientLight = new THREE.AmbientLight(0x404040, 0.5); // soft white light
    scene.add(ambientLight);

    // Mouse controls for moving and resizing rows
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    let selectedRack = null;
    let isResizing = false;

    const _this = this;

    const textureLoader = new THREE.TextureLoader();
    this.topTexture = textureLoader.load("/textures/shelf.jpg");
    this.sideTextureFront = textureLoader.load("/textures/boxes.png");
    this.sideTextureBack = textureLoader.load("/textures/boxes.png");
    this.sideTextureBack.center.set(0.5, 0.5);
    this.sideTextureBack.rotation = Math.PI;
    this.sideTextureRight = textureLoader.load("/textures/boxes.png");
    this.sideTextureRight.center.set(0.5, 0.5);
    this.sideTextureRight.rotation = Math.PI / 2;
    this.sideTextureLeft = textureLoader.load("/textures/boxes.png");
    this.sideTextureRight.center.set(0.5, 0.5);
    this.sideTextureLeft.rotation = Math.PI / -2;

    const canvas = renderer.domElement;

    canvas.addEventListener("click", () => {
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(
        _this.rows.flatMap((row) => row),
        true
      );

      if (intersects.length > 0) {
        const clickedObject = intersects[0].object;
        console.log("Clicked object:", clickedObject);
        _this.selectedRack = clickedObject;
        _this.selectedRow = clickedObject.parent;
      }
    });

    // Mouse events
    canvas.addEventListener("mousedown", (event) => {
      if (event.button === 2) isRightMouseDown = true; // Right mouse button
      if (event.button === 0) isLeftMouseDown = true; // Left mouse button
      prevMousePosition.x = event.clientX;
      prevMousePosition.y = event.clientY;

      // Normalize mouse coordinates ([-1, 1] range)
      const canvasBounds = renderer.domElement.getBoundingClientRect();

      // Convert mouse position to normalized device coordinates (NDC)
      mouse.x =
        ((event.clientX - canvasBounds.left) / canvasBounds.width) * 2 - 1;
      mouse.y =
        -((event.clientY - canvasBounds.top) / canvasBounds.height) * 2 + 1;

      // Update raycaster
      raycaster.setFromCamera(mouse, camera);

      // Check for intersection with rows (or compartments)
      const intersects = raycaster.intersectObjects(
        _this.rows.flatMap((row) => row),
        true
      );

      if (intersects.length > 0) {
        selectedRack = intersects[0].object.parent;
        isResizing = event.shiftKey;
      }
    });

    canvas.addEventListener("mouseup", () => {
      isRightMouseDown = false;
      isLeftMouseDown = false;
    });

    canvas.addEventListener("mousemove", (event) => {
      if (this.editorMode && isLeftMouseDown && selectedRack) {
        if (isResizing) {
          const deltaX = event.clientX - prevMousePosition.x;
          if (selectedRack.scale.x > 0.01 || deltaX > 0) {
            selectedRack.scale.x += deltaX * 0.01;
          }
        } else {
          // Logic for moving the selected row
          const deltaX =
            Math.round(event.clientX / 10) * 10 -
            Math.round(prevMousePosition.x / 10) * 10;
          const deltaY =
            Math.round(event.clientY / 10) * 10 -
            Math.round(prevMousePosition.y / 10) * 10;

          const radians = scene.rotation.z;

          const xNew = deltaX * Math.cos(radians) - deltaY * Math.sin(radians);
          const yNew = deltaX * Math.sin(radians) + deltaY * Math.cos(radians);

          selectedRack.position.x += xNew * 0.015;
          selectedRack.position.y -= yNew * 0.015;
        }
        prevMousePosition.x = event.clientX;
        prevMousePosition.y = event.clientY;

        return;
      }

      const deltaX = event.clientX - prevMousePosition.x;
      const deltaY = event.clientY - prevMousePosition.y;
      prevMousePosition.x = event.clientX;
      prevMousePosition.y = event.clientY;

      if (isRightMouseDown) {
        // Rotate camera (orbit around target)
        const angleY = deltaY * 0.005;
        const angleX = deltaX * 0.005;

        scene.rotation.z += Math.PI * angleX;
        const offset = new THREE.Vector3().subVectors(
          camera.position,
          cameraTarget
        );

        // Rotate the offset vector around the X axis (vertical rotation)
        offset.applyAxisAngle(new THREE.Vector3(1, 0, 0), -angleY);

        // Set the camera position
        camera.position.copy(cameraTarget).add(offset);
        camera.lookAt(cameraTarget);
      }

      if (isLeftMouseDown) {
        const panSpeed = 0.01;

        const cameraRight = new THREE.Vector3();
        cameraRight
          .crossVectors(
            camera.up,
            camera.getWorldDirection(new THREE.Vector3())
          )
          .normalize();
        const cameraUp = new THREE.Vector3(0, 0, 1);

        // Calculate pan offset
        const panOffset = new THREE.Vector3();
        panOffset.addScaledVector(
          cameraRight,
          (deltaX * panSpeed * zoomDistance) / 10
        ); // Pan horizontally
        panOffset.addScaledVector(
          cameraUp.cross(cameraRight).normalize(),
          (-deltaY * panSpeed * zoomDistance) / 10
        ); // Pan vertically

        camera.position.add(panOffset);
        cameraTarget.add(panOffset);
      }
    });

    canvas.addEventListener("mouseup", () => {
      selectedRack = null;
      isResizing = false;
    });

    const minZoomDistance = 2; // Minimum distance to the target
    const maxZoomDistance = 300; // Maximum distance to the target

    // Mouse wheel for zoom
    canvas.addEventListener("wheel", (event) => {
      const zoomAmount = event.deltaY * 0.01 * zoomSpeed;
      const direction = new THREE.Vector3()
        .subVectors(camera.position, cameraTarget)
        .normalize();

      // Calculate the new position
      const newPosition = camera.position
        .clone()
        .addScaledVector(direction, zoomAmount);

      // Calculate the distance to the target
      const distanceToTarget = newPosition.distanceTo(cameraTarget);

      // Clamp the distance to within min and max zoom limits
      if (
        distanceToTarget >= minZoomDistance &&
        distanceToTarget <= maxZoomDistance
      ) {
        zoomDistance = distanceToTarget;
        camera.position.copy(newPosition);
      }
    });

    // Disable default right-click menu
    canvas.addEventListener("contextmenu", (event) => event.preventDefault());

    // Handle window resize
    _this.onWindowResize = () => {
      camera.aspect =
        (_this.$refs.warehouse_viewer.offsetWidth - 40) /
        (window.innerHeight - 180);
      camera.updateProjectionMatrix();
      renderer.setSize(
        _this.$refs.warehouse_viewer.offsetWidth - 40,
        window.innerHeight - 180
      );
    };

    window.addEventListener("resize", _this.onWindowResize);

    // Animation loop
    _this.animationFrameId = null;
    function animate() {
      _this.animationFrameId = requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }
    animate();

    if (this.warehouse > 0) {
      this.loadWarehouse();
    }
  },
  computed: {
    editorMode() {
      return this.mode === "editor";
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    selectedCompartments() {
      return this.selectedRow?.userData?.compartments?.find(
        (e) => e.number === this.selectedRack?.userData?.rack
      );
    },
  },
  watch: {
    warehouse() {
      if (this.warehouse > 0) {
        this.loadWarehouse();
      }
      if (this.mode == "unallocatedInventory") {
        this.loadUnallocatedInventory();
      }
    },
    warehouseHeight() {
      this.setWarehouseSize();
    },
    warehouseWidth() {
      this.setWarehouseSize();
    },
    editorMode() {
      for (let i = 0; i < this.rows.length; i++) {
        const compartments = this.rows[i].children;

        for (let i = 0; i < compartments.length; i++) {
          this.setCompartmentColor(compartments[i]);
        }
      }
    },
    selectedRow() {
      this.loadRow();
    },
  },
  methods: {
    rotate() {
      this.selectedRow.rotation.z -= Math.PI / 4;
    },
    print() {
      printJS(
        `${tenant.getApiBaseUrl()}/warehouse/${
          this.warehouse
        }/map/barcode?rows[]=${this.selectedRow.userData.id}&_token=${
          this.store.state.token
        }`
      );
    },
    removeRow(row, removeFromArray = true) {
      for (let i = row.children.length - 1; i >= 0; i--) {
        const compartment = row.children[i];

        // Dispose of geometry
        if (compartment.geometry) {
          compartment.geometry.dispose();
        }

        // Dispose of material
        if (compartment.material) {
          if (Array.isArray(compartment.material)) {
            compartment.material.forEach((mat) => mat.dispose());
          } else {
            compartment.material.dispose();
          }
        }

        // Remove from parent and scene
        compartment.removeFromParent();
        scene.remove(compartment);
      }
      row.removeFromParent();
      scene.remove(row);

      if (removeFromArray) {
        let i = this.rows.findIndex((e) => e.userData.id === row.userData.id);
        if (i > -1) {
          this.rows.splice(i, 1);
        }
      }
    },
    sizesAddModal() {
      this.$refs.addSizeModal.showModal();
    },
    createNewSize(data) {
      http.fetch("/warehouses/map/slot-size", data).then((response) => {
        this.sizes.push(response.data);
      });
    },
    loadWarehouse() {
      // Remove existing rows
      this.rows.forEach((row) => {
        this.removeRow(row, false);
      });
      this.rows.length = 0;
      renderer.renderLists.dispose();
      renderer.clear();

      http.fetch("/warehouses/" + this.warehouse + "/map").then((data) => {
        this.warehouseWidth = data.warehouse.map_width ?? 10;
        this.warehouseHeight = data.warehouse.map_height ?? 10;
        this.sizes = data.sizes;
        this.setWarehouseSize();

        // Add rows from the file
        data.rows.forEach((row) => {
          this.addNewRow(
            row.pos_x,
            row.pos_y,
            row["length"],
            row.compartments.length,
            row.id,
            row.compartments,
            row.aisle_number,
            row.rotate
          );
        });
      });
    },

    saveWarehouse() {
      const data = {
        warehouse: { width: this.warehouseWidth, height: this.warehouseHeight },
        rows: this.rows.map((e) => ({
          x: e.position.x,
          y: e.position.y,
          rotate: Math.round(e.rotation.z * (180 / Math.PI)),
          length: e.userData.length,
          aisle: e.userData.aisle,
          id: e.userData?.id ?? null,
          compartments: e.children.map((compartment) => ({
            rack: compartment.userData.rack,
            slots: compartment.userData.slots,
            id: compartment.userData?.id ?? null,
          })),
        })),
      };
      http.fetch("/warehouses/" + this.warehouse + "/map", data).then((res) => {
        console.log(res);
        this.loadWarehouse();
      });
    },
    loadRow() {
      if (
        this.selectedRow.userData.id === undefined ||
        this.selectedRow.userData.id === null
      ) {
        return;
      }
      http
        .fetch(
          "/warehouses/" +
            this.warehouse +
            "/map/row/" +
            this.selectedRow.userData.id
        )
        .then((data) => {
          this.selectedRow.userData.compartments = data.row.compartments;
        });
    },
    setMovement() {
      const robotPath = [
        { x: 0, y: 0 },
        { x: 0, y: 5 },
        { x: 9, y: 5 },
        { x: 9, y: 3 },
      ];

      const points = robotPath.map(
        (coord) => new THREE.Vector3(coord.x, coord.y, 0.02)
      );

      const line = new MeshLine();
      line.setPoints(points);

      const material = new MeshLineMaterial({
        color: 0xff0000,
        lineWidth: 0.2,
        dashArray: 1,
        dashOffset: 1,
        dashRatio: 0,
      });

      const lineMesh = new THREE.Mesh(line, material);
      scene.add(lineMesh);
    },
    setWarehouseSize() {
      // Remove the previous grid helper if it exists
      if (gridHelper) scene.remove(gridHelper);

      // Create the grid helper with divisions matching the floor dimensions
      gridHelper = new THREE.GridHelper(
        Math.max(this.warehouseWidth, this.warehouseHeight),
        Math.max(this.warehouseWidth, this.warehouseHeight),
        0xbbbbbb,
        0xaaaaaa
      ); // Darker grid lines
      gridHelper.position.set(0, 0, 0.01); // Position the grid slightly above the floor
      gridHelper.rotation.x = -Math.PI / 2; // Rotate the floor to lie flat on the XY plane
      // Add the grid helper to the scene
      scene.add(gridHelper);

      // Remove the previous floor if it exists
      if (floor) scene.remove(floor);

      // Create the floor geometry based on width and height
      const floorGeometry = new THREE.PlaneGeometry(
        this.warehouseWidth,
        this.warehouseHeight
      );
      const textureLoader = new THREE.TextureLoader();
      const floorTexture = textureLoader.load("/textures/floor.jpg");
      floorTexture.colorSpace = THREE.SRGBColorSpace;
      floorTexture.wrapS = THREE.RepeatWrapping;
      floorTexture.wrapT = THREE.RepeatWrapping;
      floorTexture.repeat.set(2, 2);
      const floorMaterial = new THREE.MeshBasicMaterial({
        map: floorTexture,
        color: 0xd3d3d3,
        side: THREE.DoubleSide,
      });
      floor = new THREE.Mesh(floorGeometry, floorMaterial);

      floor.position.set(0, 0, 0); // Position the floor in the center
      scene.add(floor);

      let geometry = new THREE.BoxGeometry(this.warehouseWidth, 1, 0.2);
      const texture = textureLoader.load("/textures/zinc.jpg");
      texture.colorSpace = THREE.SRGBColorSpace;
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(10, 2);

      const material = new THREE.MeshBasicMaterial({ map: texture });

      if (wall1) scene.remove(wall1);
      wall1 = new THREE.Mesh(geometry, material);
      wall1.rotation.x = -Math.PI / 2;
      wall1.position.set(0, this.warehouseHeight / 2, 0.5);
      scene.add(wall1);

      if (wall2) scene.remove(wall2);
      wall2 = new THREE.Mesh(geometry, material);
      wall2.rotation.x = -Math.PI / 2;
      wall2.position.set(0, this.warehouseHeight / -2, 0.5);
      scene.add(wall2);

      if (wall3) scene.remove(wall3);
      geometry = new THREE.BoxGeometry(this.warehouseHeight, 1, 0.2);
      wall3 = new THREE.Mesh(geometry, material);
      wall3.rotation.x = -Math.PI / 2;
      wall3.rotation.y = -Math.PI / 2;
      wall3.position.set(this.warehouseWidth / 2, 0, 0.5);
      scene.add(wall3);

      if (wall4) scene.remove(wall4);
      wall4 = new THREE.Mesh(geometry, material);
      wall4.rotation.x = -Math.PI / 2;
      wall4.rotation.y = -Math.PI / 2;
      wall4.position.set(this.warehouseWidth / -2, 0, 0.5);
      scene.add(wall4);
    },
    addNewRow(
      x,
      y,
      length,
      numCompartments,
      id = null,
      compartments = null,
      aisle = null,
      rotate = 0
    ) {
      // Group to hold the compartments
      const rowGroup = new THREE.Group();

      rowGroup.position.x = x;
      rowGroup.position.y = y;
      rowGroup.rotation.z = rotate * (Math.PI / 180);

      for (let i = 0; i < numCompartments; i++) {
        const materials = [
          new THREE.MeshBasicMaterial({
            map: this.sideTextureRight.clone(),
            color: 0xe5dafb,
          }), // top
          new THREE.MeshBasicMaterial({
            map: this.sideTextureLeft.clone(),
            color: 0xe5dafb,
          }), //side
          new THREE.MeshBasicMaterial({
            map: this.sideTextureBack.clone(),
            color: 0xe5dafb,
          }), //back
          new THREE.MeshBasicMaterial({
            map: this.sideTextureFront.clone(),
            color: 0xe5dafb,
          }), //front
          new THREE.MeshBasicMaterial({
            map: this.topTexture.clone(),
            color: 0xe5dafb,
          }), //side
          new THREE.MeshBasicMaterial({
            map: this.topTexture.clone(),
            color: 0xe5dafb,
          }), //bottom
        ];

        // Create each compartment
        const compartmentWidth = length / numCompartments;
        const geometry = new THREE.BoxGeometry(compartmentWidth, 0.4, 0.5);
        const compartment = new THREE.Mesh(geometry, materials);

        // Position each compartment
        compartment.position.set(i * compartmentWidth - length / 2, 0, 0.25);
        compartment.userData.fullness = 0; // Initial fullness level (0 for empty)
        if (compartments !== null && compartments !== undefined) {
          compartment.userData.slots = [];
          compartment.userData.rack = compartments[i]?.number;

          for (const slot of compartments[i]?.slots) {
            compartment.userData.slots.push({
              id: slot.id,
              number: slot.rack_number,
              size: slot.warehouse_slot_size_id,
            });
          }
        } else {
          if (Object.keys(this.newCompartmentSlots).length > 0) {
            const alphabetArray = Array.from({ length: 26 }, (_, i) =>
              String.fromCharCode(65 + i)
            );

            compartment.userData.slots = [];
            compartment.userData.rack = i + 1;

            for (const key in this.newCompartmentSlots) {
              for (let i = 0; i < this.newCompartmentSlots[key]; i++) {
                compartment.userData.slots.push({
                  number:
                    alphabetArray[key - 1] +
                    "" +
                    String(i + 1).padStart(2, "0"),
                  size: this.newCompartmentSlotSizes[key],
                });
              }
            }
          } else {
            compartment.userData.rack = i + 1;
            compartment.userData.slots = [
              {
                number: "01",
                size: 1,
              },
            ];
          }
        }
        rowGroup.add(compartment);
      }

      this.newCompartmentSlots = {};
      this.newCompartmentSlotSizes = {};
      rowGroup.userData.length = length;
      rowGroup.userData.numCompartments = numCompartments;
      if (aisle !== null && aisle !== undefined) {
        rowGroup.userData.aisle = aisle;
      }
      if (id !== null && id !== undefined) {
        rowGroup.userData.id = id;
      }

      scene.add(rowGroup);

      // Save the row to the list (with compartments)
      this.rows.push(rowGroup);
    },
    refreshFullness() {
      for (let i = 0; i < this.rows.length; i++) {
        let rndValues = [];
        for (let i2 = 0; i2 < this.rows[i].userData.numCompartments; i2++) {
          rndValues.push(Math.random());
        }
        this.updateRowFullness(i, rndValues);
      }
    },
    addSlot() {
      if (
        this.selectedRack.userData.slots === undefined ||
        this.selectedRack.userData.slots === null
      ) {
        this.selectedRack.userData.slots = [];
      }

      this.selectedRack.userData.slots.push({
        number: this.selectedRack.userData.slots.length + 1,
        size: this.selectedRack.userData.slots[
          this.selectedRack.userData.slots.length - 1
        ].size,
      });
    },
    generateRackNumbers() {
      let c = 0;
      for (const row of this.rows) {
        if (row.userData?.aisle === this.selectedRow.userData.aisle) {
          c++;
        }
      }
      let s = 1;
      for (const row of this.rows) {
        if (row.userData?.aisle === this.selectedRow.userData.aisle) {
          let i = s;
          for (const rack of row.children) {
            rack.userData.rack = i;
            i += c;
          }
          s++;
        }
      }
    },
    generateRandomString() {
      const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      const digits = "0123456789";

      let randomString = "";

      // Generate 3 random letters
      for (let i = 0; i < 3; i++) {
        randomString += letters.charAt(
          Math.floor(Math.random() * letters.length)
        );
      }

      // Generate 2 random digits
      for (let i = 0; i < 2; i++) {
        randomString += digits.charAt(
          Math.floor(Math.random() * digits.length)
        );
      }

      return randomString;
    },
    addRow() {
      const x = 0;
      const y = 0;
      const length = this.newCompartmentLength * this.numCompartments;
      const numCompartments = this.numCompartments;

      this.addNewRow(
        x,
        y,
        length,
        numCompartments,
        null,
        null,
        this.generateRandomString()
      );
    },
    setCompartmentFullness(compartment, fullness) {
      fullness = Math.max(0, Math.min(1, fullness));
      compartment.userData.fullness = fullness;

      this.setCompartmentColor(compartment);
    },

    setCompartmentColor(compartment) {
      const fullness = compartment.userData.fullness;
      // Interpolate RGB values
      const startColor = { r: 229, g: 218, b: 251 }; // White
      const endColor = { r: 113, g: 58, b: 232 }; // Dark

      const r = Math.round(
        startColor.r +
          (endColor.r - startColor.r) * (this.editorMode ? 0 : fullness)
      );
      const g = Math.round(
        startColor.g +
          (endColor.g - startColor.g) * (this.editorMode ? 0 : fullness)
      );
      const b = Math.round(
        startColor.b +
          (endColor.b - startColor.b) * (this.editorMode ? 0 : fullness)
      );

      // Convert to hexadecimal color
      const colorHex = (r << 16) | (g << 8) | b; // Combine RGB to a single hexadecimal value
      for (const material of compartment.material) {
        material.color.setHex(colorHex);
      }
    },

    updateRowFullness(rowIndex, fullnessLevels) {
      if (rowIndex < 0 || rowIndex >= this.rows.length) return;

      const row = this.rows[rowIndex];
      const compartments = row.children;

      for (let i = 0; i < compartments.length; i++) {
        const fullness = fullnessLevels[i] || 0; // Default to 0 if no value provided
        this.setCompartmentFullness(compartments[i], fullness);
      }
    },
    loadUnallocatedInventory() {
      this.mode = "unallocatedInventory";
      http
        .fetch("/warehouses/" + this.warehouse + "/map/unallocated-inventory")
        .then((data) => {
          this.unallocatedInventory = data.data;
        });
    },
  },
};
</script>

<style scoped>
.warehouse-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
#ui {
  position: absolute;
  top: 80px;
  right: 102px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 392px;
  padding: 2em 1em;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
input,
button {
  margin: 5px 0;
  display: block;
}
</style>
